import Swiper, { Navigation, Autoplay, Pagination } from "swiper";

const swiper = new Swiper(".slide--testimonials", {
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	navigation: {
		nextEl: ".hero-arrow--next",
		prevEl: ".hero-arrow--prev",
	},
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
		clickable: true,
	},
	modules: [Autoplay, Navigation, Pagination],
	breakpoints: {
		1024: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1392: {
			slidesPerView: 4,
			spaceBetween: 50,
		},
	},
});

const inputs = document.querySelectorAll("input");

inputs.forEach((input) => {
	input.addEventListener("blur", (event) => {
		if (event.target.value) {
			input.classList.add("is-valid");
		} else {
			input.classList.remove("is-valid");
		}
	});
});

const selects = document.querySelectorAll("select");

selects.forEach((input) => {
	input.addEventListener("blur", (event) => {
		if (event.target.value) {
			input.classList.add("is-valid");
		} else {
			input.classList.remove("is-valid");
		}
	});
});
